@import url('https://fonts.googleapis.com/css2?family=Inter:wght@100..900&display=swap');
/* @import url('https://fonts.googleapis.com/css2?family=PT+Sans:ital,wght@0,400;0,700;1,400;1,700&display=swap'); */

body {
  margin: 0;
  background-color: hsl(26, 47%, 97%);
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
  monospace;
}